import React from 'react'
import thankyouStyles from './thank-you.module.css'
import Layout from '../components/Layout'
import { Link } from 'gatsby'
import { empty_cart, remove_cart_from_storage } from '../redux/cart.redux';
import { connect } from 'react-redux';


class ThankYouPage extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
      this.props.empty_cart();
      this.props.remove_cart_from_storage();
    }

  render() {
    var content = "";
    if (this.props.location.state && this.props.location.state.content) {
        content = this.props.location.state.content
    } else {
      content = `
      Dear friend,
      
      Congratulations on your purchase. The receipt has been sent to your email, we'll send you another email as soon as it's shipped! It has been our pleasure to serve you, and we believe you'll fall in love with this new gear! 

      If we can be of further assistance, please let us know.

      Keep Innvating,

      Sonic Instruments
      info@sonic-instruments.co
      `
    }
    return (
      <Layout location={this.props.location} title={'Contact'}>
        <div className={thankyouStyles.cover} />
        <div className={thankyouStyles.container}>
            <h1>Thank you for your order!</h1>
            <p style={{fontSize: '16px', fontWeight: 300, whiteSpace: 'pre-line', lineHeight: '1.5'}}>{content}</p>
            <div style={{paddingBottom: '120px', marginTop: '64px'}}>
                <Link to="/">
                    <button>Back to Home</button>
                </Link>
            </div>
        </div>
      </Layout>
    )
  }
}

const actionCreators = { remove_cart_from_storage, empty_cart }

export default connect(null, actionCreators)(ThankYouPage)
